import React from 'react'
import Image from '../components/Image'

const ClientLogo = ({ id, image, altText, url }) => (
  <div className="md:w-1/6 xl:px-8 lg:px-6 md:px-6 px-2 md:py-10 sm:py-8 py-10">
    <a id={id} href={url} alt={altText} target="_blank">
      <Image src={image} alt={altText} disableLightBox={true} 
        className={`object-bottom md:w-auto w-2/3 mx-auto my-auto logo-customer`} />
    </a>
  </div>
)

export default ClientLogo