import React from 'react'
import Layout from '../components/layout/Layout'
import Clients from '../components/Clients'
import Card from '../components/Card'
import Image from '../components/Image'

import PageData from '../data/about-data.yaml'

const About = () => (
  <Layout>
    <section id="about">
      <div className="container mx-auto p-8 pt-16 text-center">
        <h1 className="text-primary-500 font-semibold tracking-wide uppercase">Company</h1>
        <h1 className="md:text-4xl text-3xl font-semibold tracking-tight justify-center mt-2">{PageData.title}</h1>
        <p className="md:text-lg font-light text-center mt-4 space-y-4">
          {!PageData.paragraphs ? null : 
            PageData.paragraphs.map((paragraph, i) => {
              return <p key={i} className="">{paragraph}</p>
          })}
        </p>
      </div>
    </section>

    <section id="iso" className="md:py-12 py-10 my-6 bg-gray-100 border border-gray-300 shadow-inner">
      <div className="container mx-auto md:px-8 px-4">
        <Card className="flex flex-row mx-auto md:p-8">
          <div className="flex-col md:w-10 w-20 mx-auto">
            <Image src="Quality.jpg" alt="quality" />
          </div>
          <div className="flex flex-col text-center mx-auto px-4">
            <p className="md:text-lg text-md text-black font-semibold w-full my-auto">
              ViziRail is an ISO9001 certified quality organisation committed to applying leading
              edge IT to the rail industry.
            </p>
          </div>
        </Card>
      </div>
    </section>

    <section id="clients" className="md:pt-8 pt-16">
      <Clients id={'client-list'} />
    </section>
  </Layout>
)
export default About