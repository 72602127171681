import React from 'react'
import ClientLogo from './ClientLogo'

import ClientData from '../data/client-data.yaml'

const Clients = ({ id }) => (
  <div id={id} className="container mx-auto lg:px-16 sm:px-8 items-center">
    <div className="lg:text-xl text-center">
      <p className="lg:text-xl font-semibold tracking-wide text-primary-500 uppercase">{ClientData.title}</p>
      <p className="font-light">{ClientData.overview}</p>
    </div>
    <div className="md:flex md:flex-row md:flex-wrap md:justify-center grid sm:grid-cols-4 grid-cols-2 md:pt-0 pt-2">
      {!ClientData.clients ? null : 
        ClientData.clients.map((client, i) => {
          return <ClientLogo key={client.id} id={client.id} image={client.image} altText={client.title} url={client.url} />
        })}
    </div>
  </div>
)

export default Clients